'use client'

import { getCookie } from 'cookies-next'
import { usePathname, useRouter } from 'next/navigation'
import { useEffect, useState } from 'react'

import useAuth from '@/hooks/useAuth'
import useDbSettings from '@/hooks/useDbSettings'

import Matcher from '@/components/Admin/Matcher'
import Chatbot, { IndexPageContext } from '@/components/Chatbot'
import IndexHeader from '@/components/Index/IndexHeader'

import { switchLanguage } from '@/i18n'
import { cn } from '@/utils/clsx'

const Home = () => {
  const { error, isLoading, user } = useAuth()
  const router = useRouter()
  const pathname = usePathname()
  const [matcherOpen, setMatcherOpen] = useState(true)
  const { settings } = useDbSettings()

  useEffect(() => {
    if (error) {
      router.replace(
        `/login${pathname ? '?redirect=' + encodeURIComponent(pathname) : ''}`
      )
    }
    if (settings?.requireSubscription && !user?.subscribed) {
      router.replace('/landing')
    }
  }, [error, user, settings])

  // Set language from cookie
  const lang = getCookie('locale')
  if (lang) {
    setTimeout(() => switchLanguage(lang))
  }

  return (
    <>
      {isLoading || error ? (
        <></>
      ) : (
        <>
          {matcherOpen ? (
            <IndexPageContext.Provider value={true}>
              <main
                className={cn(
                  'relative h-screen flex flex-col bg-background dark:bg-dark-background shadow-md rounded'
                )}
              >
                <div className='w-full'>
                  <IndexHeader />
                </div>
                <Matcher setMatcherOpen={setMatcherOpen} />
              </main>
            </IndexPageContext.Provider>
          ) : (
            <Chatbot indexPage />
          )}
        </>
      )}
    </>
  )
}

export default Home
